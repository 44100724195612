<template>
  <loading v-if="loading" />

  <form v-else @submit.prevent="createAccount">
    <b-field label="Email">
      <b-input
        ref="email"
        v-model="form.email"
        :disabled="processing"
        type="email"
        autocomplete="email"
        placeholder="Your email"
      />
    </b-field>

    <create-password-field
      :disabled="processing"
      @validated="form.password = $event"
    />

    <b-field>
      <p class="has-text-grey">
        <small>
          By clicking "Continue" I agree to the fixed.net
          <a
            target="_blank"
            href="https://fixed.net/knowledge-base/article/terms-and-conditions"
            >Terms of Service</a
          >
        </small>
        .
      </p>
    </b-field>

    <step-controls
      :enabled="formIsValid"
      :processing="processing"
      @back="$emit('back')"
    />
  </form>
</template>

<script>
export default {
  name: "CreateAccountStep",
  components: {
    "create-password-field": () => import("@shared/account/_createPassword")
  },
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: this.payload.userId ? true : false,
      processing: false,
      form: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    formIsValid() {
      if (!this.$v.isEmail(this.form.email)) return false;
      if (!this.form.password) return false;
      return true;
    },
    formIsDisabled() {
      return this.processing || !this.formIsValid;
    }
  },
  watch: {
    userId() {
      this.$emit("payload", { userId: this.userId });
      this.$emit("next");
    }
  },
  created() {
    this.$nextTick(() => {
      this.$refs.email && this.$refs.email.$el.querySelector("input").focus();
    });
    setTimeout(() => {
      this.loading = false;
      if (this.userId) {
        this.$emit("payload", { userId: this.userId });
        this.$emit("next");
      }
    }, 660);
  },
  methods: {
    createAccount() {
      if (this.formIsDisabled) return;
      this.processing = true;
      this.$store
        .dispatch("auth/register", {
          email: this.form.email,
          password: this.form.password,
          couponId: this.$route.query.c || null
        })
        .catch(error => {
          this.processing = false;
          this.$toast.open({
            message: error,
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
